import getSessionId from './getSessionId';

const getHeaders = () => {
  const localStorageToken = window.localStorage.getItem('AUTH_TOKEN');

  return {
    Authorization: `Bearer ${localStorageToken}`,
    'Session-Id': getSessionId(),
  };
};

export default getHeaders;
