export enum Permission {
  viewOffers = 'VIEW_OFFERS',
  updateOffers = 'UPDATE_OFFERS',
  viewTicketingOffers = 'VIEW_TICKETING_OFFERS',
  updateTicketingOffers = 'UPDATE_TICKETING_OFFERS',
  viewCampaigns = 'VIEW_CAMPAIGNS',
  updateCampaigns = 'UPDATE_CAMPAIGNS',
  viewGroup = 'VIEW_GROUP',
  viewConfig = 'VIEW_CONFIG',
  updateConfig = 'UPDATE_CONFIG',
  viewSeatMapVisualizer = 'VIEW_SEAT_MAP_VISUALIZER',
  viewEmailVisualizer = 'VIEW_EMAIL_VISUALIZER',
  viewReports = 'VIEW_REPORTS',
}
