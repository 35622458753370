export enum PartnerType {
  affiliate = 'affiliate',
  airline = 'airline',
  cruise = 'cruise',
  hotel = 'hotel',
  rail = 'rail',
}

export interface PartnerInfo {
  pid: string;
  companyCode: string;
  partnerShortName: string;
  partnerType: PartnerType;
}

export interface Configuration {
  partnerShortName?: string;
  partnerName?: string;
  partnerType?: PartnerType;
  companyCode?: string;
  partnerWebsite?: string;
  language: string;
  supportedCurrencies?: string[];
  currencyFormatSettings?: CurrencyFormatSettings;
  currency?: string;
  defaultCurrency?: string;
  up?: UpConfig;
}

export interface UpConfig {
  currency: UpCurrencyConfigs;
}

export interface UpCurrencyConfigs {
  global?: UpCurrencyConfigEntry;
  pointsSpecific?: UpCurrencyConfigEntry;
  currencySpecific?: UpCurrencyConfigEntry[] | null;
}

export interface UpCurrencyConfigEntry {
  currencyCode?: string;
  stringFormat?: string;
  groupingSeparator?: string;
  decimalSeparator?: string;
  fractionDigits?: number;
  currencySpecificFormats?: UpCurrencySpecificFormats[];
}

export interface UpCurrencySpecificFormats {
  currencyCode?: string;
  stringFormat?: string;
}

export interface CurrencyFormatSettings {
  stringFormat: string | null;
  fractionDigits: number;
  currencySpecificFormats: CurrencySpecificFormats[];
}

export interface CurrencySpecificFormats {
  currencyCode: string;
  stringFormat: string | null;
  languageSpecificFormats: LanguageSpecificFormats[];
}

export interface LanguageSpecificFormats {
  language: string;
  stringFormat: string | null;
}
