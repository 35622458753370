import { useState, useLayoutEffect } from 'react';
import type { Theme } from '@mui/material';

import buildTheme from './buildTheme';
import { fetchThemesByPid } from '../../utils/theme/fetchThemesByPid';
import useAppConfig from 'common/states/config/useAppConfig';

export interface UseBuildThemeHookProps {
  pid?: string;
  appName?: string;
}

export const useBuildTheme = ({ appName, pid }: UseBuildThemeHookProps) => {
  const [theme, setTheme] = useState<Theme>();

  useLayoutEffect(() => {
    const fetchTheme = async () => {
      let themeToUse = buildTheme();
      if (appName) {
        const partnerThemes = await fetchThemesByPid(appName, pid);
        const mode = partnerThemes[0]?.palette?.mode;
        if (partnerThemes.length > 0) {
          themeToUse = buildTheme(partnerThemes, mode);
        } else {
          console.error(`Missing partner theme for ${pid}. Using default theme`);
        }
      }

      setTheme(themeToUse);
    };
    fetchTheme().catch(console.error);
  }, [appName, pid]);

  return theme;
};

const getAppNameFromPath = () => {
  const pathToAppName: Record<string, string> = {
    '/': 'login',
    '/auth': 'login',
    '/ami': 'lite',
    '/studio': 'studio',
  };

  const path = Object.keys(pathToAppName)
    .reverse()
    .find((key) => {
      if (key === '/') return window.location.pathname === key;
      return window.location.pathname.startsWith(key);
    });

  return path ? pathToAppName[path] : undefined;
};

const useCurrentTheme = () => {
  const appName = getAppNameFromPath();
  const { partnerInfo } = useAppConfig().states;
  const theme = useBuildTheme({ appName, pid: partnerInfo?.pid });

  return theme;
};

export default useCurrentTheme;
