import { type PaletteMode, type PaletteOptions, type Palette, type ThemeOptions, buttonClasses } from '@mui/material';
import { alpha, createTheme } from '@mui/material/styles';

import commonConfig from 'common/configs/commonConfig';
import plusgradeAssets from 'common/plusgradeAssets';
import structuredMerge from 'common/utils/structuredMerge';
import type { PartnerThemeOptions, ThemeOptionsBuilder } from 'types/theme';
import type {} from '@mui/lab/themeAugmentation'; // https://mui.com/material-ui/about-the-lab/
import type {} from '@mui/x-charts/themeAugmentation'; // https://mui.com/x/react-charts/getting-started/#typescript
import { loadingButtonClasses } from '@mui/lab';

/**
 * Please don't change this file without discussing with the portal team beforehand.
 * Any modifidications in this file may have deep impact on all the products.
 * @param defaultPalette
 * @param palettes
 * @returns
 */

// we need a tricky merge to erase dark, light and contrastText from defaultTheme as soon as at least main is defined
const makePalette = (defaultPalette: PaletteOptions, palettes: Array<PaletteOptions | undefined>) =>
  createTheme({
    palette: palettes.filter(Boolean).reduce((acc, p) => {
      return { ...acc, ...p };
    }, defaultPalette),
  }).palette;

const makeFonts = (partnerTheme?: PartnerThemeOptions) => {
  return {
    fontRegular: `${commonConfig.baseUrlCDN}/fonts/Avenir/AvenirNext-Regular.woff`,
    fontBold: `${commonConfig.baseUrlCDN}/fonts/Avenir/AvenirNext-Bold.woff`,
    ...partnerTheme?.customFonts,
  };
};

const makePartner = (
  defaultPartner: PartnerThemeOptions,
  palette: Palette,
  partnerThemes: ThemeOptionsBuilder['partner'][],
) =>
  createTheme(
    { partner: defaultPartner },
    ...partnerThemes.map((t) => {
      return {
        partner: typeof t === 'function' ? t({ palette }) : t ?? {},
      };
    }),
  ).partner;

const lightModeDefinitions = {
  primary: {
    main: '#004A9C',
    light: '#00A3ED',
    dark: '#03274F',
    contrastText: '#fff',
  },
  secondary: {
    main: '#00A3ED',
    contrastText: '#fff',
  },
  tertiary: {
    main: '#00000099',
    contrastText: '#fff',
  },
  link: '#004A9C',
  background: {
    default: '#F4F5F6',
    paper: '#FFFFFF',
    dark: '#2A2A2A',
  },
  success: {
    light: '#D5EDDB',
    main: '#98C0A2',
    dark: '#0D8756',
    contrastText: 'black',
  },
  grey: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  error: {
    light: '#EACED3',
    main: '#DD8899',
  },
  warning: {
    light: '#F7E7BF',
    main: '#FFB800',
    dark: '#A16500',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.60)',
    disabled: 'rgba(0, 0, 0, 0.28)',
  },
  action: {
    disabledBackground: '#BDBDBD',
    disabled: 'rgba(255, 255, 255, 0.58)',
  },
  textOnDark: {
    primary: '#ffffff',
    secondary: 'rgba(255, 255, 255, 0.74)',
    disabled: 'rgba(255, 255, 255, 0.48)',
  },
};

const darkModeDefinitions = structuredMerge(lightModeDefinitions, {
  background: {
    default: '#000414',
  },
  error: {
    main: '#E3375A',
  },
});

const buildTheme = (partnerThemes: ThemeOptionsBuilder[] = [], mode: PaletteMode = 'light') => {
  const palette = makePalette(
    mode === 'light' ? lightModeDefinitions : darkModeDefinitions,
    partnerThemes.map((p) => p.palette),
  );

  const partner = makePartner(
    {
      name: 'Default',
      brandLogo: plusgradeAssets.logo,
      faviconUrl: plusgradeAssets.faviconUrl,
      header: {
        backgroundColor: palette.background.paper,
        color: palette.text.secondary,
        logoMaxHeight: '2.5rem',
        logoMaxWidth: '60vw',
      },
      customFonts: {
        fontRegular: `${commonConfig.baseUrlCDN}/fonts/Avenir/AvenirNext-Regular.woff`,
        fontBold: `${commonConfig.baseUrlCDN}/fonts/Avenir/AvenirNext-Bold.woff`,
      },
    },
    palette,
    partnerThemes.map((p) => p?.partner),
  );

  const { fontRegular, fontBold, fontOffsetDesktop, fontOffsetMobile } = makeFonts(partner);

  const offsetDesktopFont = (size: number) => `${size + Number(fontOffsetDesktop ?? 0)}rem`;
  const offsetMobileFont = (size: number) => `${size + Number(fontOffsetMobile ?? 0)}rem`;

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1024, // max width cards
        xl: 1280,
      },
    },
  }).breakpoints;

  const FONT_REGULAR = 'Font Regular';
  const FONT_BOLD = 'Font Bold';

  const makeFont = (fontSize: number, mobileFontSize: number, fontFamily: string, extras?: Record<string, string>) => {
    return {
      fontFamily: `${fontFamily}, 'sans-serif'`,
      fontSize: offsetDesktopFont(fontSize),
      [breakpoints.down('md')]: {
        fontSize: offsetMobileFont(mobileFontSize),
      },
      ...extras,
    };
  };

  /**
   * https://www.figma.com/design/DMEPAeXH5fLsflW3Twwbzf/10.-Atoms---AMP-V3?node-id=1226-4356&node-type=FRAME&m=dev
   */
  const shadows = {
    none: 'none' as const,
    xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
    sm: '0px 2px 6px 0px rgba(16, 24, 40, 0.06)',
    md: '0px 6px 15px -2px rgba(16, 24, 40, 0.08)',
    lg: '0px 8px 24px -3px rgba(16, 24, 40, 0.05), 0px 8px 24px -3px rgba(16, 24, 40, 0.10)',
    xl: '0px 20px 40px -8px rgba(16, 24, 40, 0.05), 0px 20px 40px -8px rgba(16, 24, 40, 0.10)',
    xxl: '0px 25px 60px -15px rgba(16, 24, 40, 0.12), 0px 25px 60px -15px rgba(16, 24, 40, 0.20)',
  };

  const lightModeComponents: ThemeOptions['components'] = {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Font Regular';
          src: url("${fontRegular}");
        }
        @font-face {
          font-family: 'Font Bold';
          src: url("${fontBold}");
        }
        @property --w_raw {
          syntax: '<length>';
          inherits: true;
          initial-value: 100vw;
        }
        @property --h_raw {
          syntax: '<length>';
          inherits: true;
          initial-value: 100vh;
        }
        html {
          min-height: 100%;
          position: relative;

          --w: tan(atan2(var(--w_raw), 1px));
          --h: tan(atan2(var(--h_raw), 1px));
        }
      `,
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
        },
        message: {
          width: '100%',
        },
      },
      variants: [
        {
          props: { variant: 'standard' as const },
          style: {
            border: '1px solid rgba(0, 0, 0, 0.12)',
          },
        },
        {
          props: { severity: 'success' as const },
          style: {
            backgroundColor: palette.success.light,
            color: palette.text.primary,
          },
        },
        {
          props: { severity: 'error' as const },
          style: {
            backgroundColor: palette.error.light,
            color: palette.text.primary,
          },
        },
        {
          props: { severity: 'warning' as const },
          style: {
            backgroundColor: palette.warning.light,
            color: palette.text.primary,
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.paper,
          color: palette.text.primary,
          boxShadow: shadows.xs,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.grey['300'],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '1rem',
          boxShadow: shadows.sm,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1.5rem 2rem',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          fontFamily: FONT_REGULAR,
          fontWeight: 'normal',
          textTransform: 'none',
          borderRadius: '1.25rem',
          height: 'fit-content',
          padding: '0.75rem 1.5rem',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary', loading: true },
          style: {
            color: `${palette.primary.contrastText}!important`,
          },
        },
        {
          props: { variant: 'outlined', loading: true },
          style: {
            [`.${loadingButtonClasses.loadingIndicator}`]: {
              color: `${palette.action.disabledBackground} !important`,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary', loading: true },
          style: {
            color: `${palette.secondary.contrastText}!important`,
          },
        },
        {
          props: { variant: 'contained', color: 'tertiary', loading: true },
          style: {
            color: `${palette.tertiary.contrastText}!important`,
          },
        },
        {
          props: { variant: 'contained', color: 'error', loading: true },
          style: {
            color: `${palette.error.contrastText}!important`,
          },
        },
        {
          props: { variant: 'contained', color: 'warning', loading: true },
          style: {
            color: `${palette.warning.contrastText}!important`,
          },
        },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: FONT_REGULAR,
          fontWeight: 'normal',
          textTransform: 'none',
          borderRadius: '1.25rem',
          height: 'fit-content',
          padding: '0.75rem 1.5rem',
          ':focus': {
            outline: 'solid 3px #BFDDF1',
          },
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.3,
          },
        },
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            padding: '0.5rem 1rem',
            fontSize: '0.875rem',
          },
        },
        {
          props: { size: 'small' },
          style: {
            padding: '0.25rem 0.75rem',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderWidth: '0.13rem',
            '&:hover': {
              borderWidth: '0.13rem',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary', disabled: false },
          style: {
            borderColor: `${palette.primary.main} !important`,
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary', disabled: false },
          style: {
            borderColor: `${palette.secondary.main} !important`,
          },
        },
        {
          props: { variant: 'outlined', disabled: true },
          style: {
            color: `${palette.action.disabledBackground} !important`,
          },
        },
        {
          props: { variant: 'contained', color: 'tertiary', disabled: false },
          style: {
            color: palette.primary.contrastText,
          },
        },
        {
          props: { color: 'error' },
          style: {
            '&:focus': {
              outline: `1px solid ${palette.error.main}`,
            },
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            '&:focus': {
              outline: `1px solid ${palette.tertiary.main}`,
            },
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...makeFont(1, 1, FONT_REGULAR),
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          borderRadius: '0.75rem',
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        input: {
          padding: '0.7rem 1.1rem',
          '::placeholder': {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingRight: 0,
          backgroundColor: palette.background.paper,
          borderRadius: '0.75rem',
          '&:not(.Mui-focused)': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.grey['300'],
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
          },
          '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
            marginInlineEnd: '1rem',
          },
          '&.Mui-error': {
            backgroundColor: palette.error.light,
            ':not(.Mui-focused):not(:hover) .MuiOutlinedInput-notchedOutline': {
              border: 'transparent',
            },
            ':hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.error.dark,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.error.dark,
            },
          },
        },
        input: {
          padding: '0.7rem 1.1rem',
        },
        notchedOutline: {
          borderColor: 'transparent',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '0.7rem 1.1rem',
        },
        iconFilled: {
          fill: palette.primary.main,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.text.primary,
          color: palette.primary.contrastText,
        },
        arrow: {
          '::before': {
            backgroundColor: palette.text.primary,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':not(:last-child)': {
            borderBottom: `1px solid ${palette.grey['300']}`,
          },
        },
      },
      variants: [
        {
          props: { selected: true },
          style: {
            backgroundColor: `${palette.primary.main} !important`,
            color: `${palette.primary.contrastText} !important`,
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: shadows.xs,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: shadows.lg,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          paddingBottom: '0.5rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: palette.primary.contrastText,
          '&.Mui-selected': {
            color: palette.primary.contrastText,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          minHeight: '0.25rem',
        },
        flexContainer: {
          justifyContent: 'space-between',
        },
      },
    },
    // Since the position of the bar below ie ".MuiTabs-indicator" is set by MUI using the 'absolute' property we need to provide
    // extra padding at the bottom of the tab to allow for the height of the indicator bar to be added in there too and maintain
    // desired padding amount
    MuiTab: {
      styleOverrides: {
        root: {
          ...makeFont(1, 0.875, FONT_REGULAR),
          padding: '0.5rem 1.5rem 0.75rem 1.5rem',
        },
      },
    },
  };

  type C = { [key: string]: unknown }; // not ideal
  const darkModeComponents: ThemeOptions['components'] = structuredMerge(lightModeComponents as C, {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.grey[300]}`,
          boxShadow: shadows.xs,
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px transparent inset',
            WebkitTextFillColor: palette.text.primary,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: palette.primary.contrastText,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.disabled}`]: {
            // canvas uses opacity: 0.3, here, but that makes the buttons in dark mode (login, auth) nearly illegible
            opacity: 1,
            color: alpha(palette.primary.contrastText, 0.3),
            backgroundColor: alpha(palette.primary.main, 0.3),
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fill: palette.primary.contrastText,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '& .MuiCheckbox-root .MuiSvgIcon-root': {
            fill: palette.primary.main,
          },
          '& .MuiFormControlLabel-root': {
            color: palette.text.primary,
          },
        },
      },
    },
  });

  return createTheme(
    {
      palette,
      partner,
      breakpoints,
      typography: {
        fontFamily: [FONT_REGULAR, FONT_BOLD, 'Arial', 'sans-serif'].join(','),
        h1: makeFont(2.563, 2.243, FONT_BOLD),
        h2: makeFont(2.25, 1.97, FONT_BOLD),
        h3: makeFont(2, 1.75, FONT_BOLD),
        h4: makeFont(1.813, 1.586, FONT_BOLD),
        h5: makeFont(1.625, 1.422, FONT_BOLD),
        subtitle1: makeFont(1.438, 1.258, FONT_REGULAR, { lineHeight: '1.5rem' }),
        'subtitle1-bold': makeFont(1.438, 1.258, FONT_BOLD, { lineHeight: '1.5rem' }),
        subtitle2: makeFont(1.25, 1.094, FONT_REGULAR),
        'subtitle2-bold': makeFont(1.25, 1.094, FONT_BOLD),
        subtitle3: makeFont(1.125, 0.984, FONT_REGULAR),
        'subtitle3-bold': makeFont(1.125, 0.984, FONT_BOLD),
        body1: makeFont(1, 0.875, FONT_REGULAR),
        'body1-bold': makeFont(1, 0.875, FONT_BOLD),
        'body1-link': makeFont(1, 0.875, FONT_REGULAR, { textDecoration: 'underline', cursor: 'pointer' }),
        'body1-link-bold': makeFont(1, 0.875, FONT_BOLD, { textDecoration: 'underline', cursor: 'pointer' }),
        body2: makeFont(0.875, 0.766, FONT_REGULAR),
        'body2-bold': makeFont(0.875, 0.766, FONT_BOLD),
        'body2-link': makeFont(0.875, 0.766, FONT_REGULAR, { textDecoration: 'underline', cursor: 'pointer' }),
        'body2-link-bold': makeFont(0.875, 0.766, FONT_BOLD, { textDecoration: 'underline', cursor: 'pointer' }),
        body3: makeFont(0.75, 0.656, FONT_REGULAR),
        'body3-bold': makeFont(0.75, 0.656, FONT_BOLD),
        'body3-link': makeFont(0.75, 0.656, FONT_REGULAR, { textDecoration: 'underline', cursor: 'pointer' }),
        'body3-link-bold': makeFont(0.75, 0.656, FONT_BOLD, { textDecoration: 'underline', cursor: 'pointer' }),
        'body3-overline': makeFont(0.75, 0.656, FONT_BOLD, {
          letterSpacing: '0.13rem',
          textTransform: 'uppercase',
        }),
        caption1: makeFont(0.688, 0.602, FONT_REGULAR),
        'caption1-bold': makeFont(0.688, 0.602, FONT_BOLD),
        'caption1-link': makeFont(0.688, 0.602, FONT_REGULAR, { textDecoration: 'underline', cursor: 'pointer' }),
        'caption1-overline': makeFont(0.688, 0.602, FONT_BOLD, {
          textTransform: 'uppercase',
          letterSpacing: '0.12rem',
        }),
        caption2: makeFont(0.625, 0.547, FONT_REGULAR),
        'caption2-bold': makeFont(0.625, 0.547, FONT_BOLD),
        'caption2-link': makeFont(0.625, 0.547, FONT_REGULAR, { textDecoration: 'underline', cursor: 'pointer' }),
        'caption2-overline': makeFont(0.625, 0.547, FONT_BOLD, {
          textTransform: 'uppercase',
          letterSpacing: '0.12rem',
        }),
        overline: makeFont(0.563, 0.493, FONT_BOLD, {
          letterSpacing: '0.13rem',
          textTransform: 'uppercase',
          lineHeight: '1.2rem',
        }),
        label: makeFont(1, 0.875, FONT_BOLD),
        'label-status': makeFont(0.6, 0.525, FONT_BOLD, { letterSpacing: '0.13rem', textTransform: 'uppercase' }),
        button: makeFont(1, 0.875, FONT_BOLD, { letterSpacing: '0.031rem', textTransform: 'none' }),
      },
      shadows: [
        // we need 25 shadows https://mui.com/system/shadows/#example
        shadows.none,
        shadows.xs,
        shadows.sm,
        shadows.md,
        shadows.lg,
        shadows.xl,
        shadows.xxl,
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
      ],
      zIndex: {
        ...createTheme().zIndex,
        sliderTooltip: 1080, // below app bar and modal
      },
      components: palette.mode === 'light' ? lightModeComponents : darkModeComponents,
    },
    ...partnerThemes.map((p) => {
      return { ...p, partner, palette };
    }),
  );
};

export default buildTheme;
