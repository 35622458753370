import type { Theme } from '@mui/material';

const removeMedia = (media: string) => media.replace('@media', '');

export const isMobileScreen = (theme: Theme) => theme.breakpoints.down('sm');

const and = (queryfirst: string, ...queries: string[]) => [queryfirst, ...queries.map(removeMedia)].join(' and');

export const isTabletScreen = (theme: Theme) => and(theme.breakpoints.down('md'), theme.breakpoints.up('sm'));

export const isDesktopScreen = (theme: Theme) => theme.breakpoints.up('md');

export const isSmallDesktopScreen = (theme: Theme) => and(isDesktopScreen(theme), theme.breakpoints.down('lg'));

export const isLargeDesktopScreen = (theme: Theme) => theme.breakpoints.up('lg');

export const anyScreen = (queryfirst: string, ...queries: string[]) =>
  [queryfirst, ...queries.map(removeMedia)].join(',');
