const argumentRegex = /{(\d+)}/;

// we can't use `replace` since replace only work with string, not JSX https://stackoverflow.com/questions/30474506/replace-part-of-string-with-tag-in-jsx
const splitByArgument = (textKeyValue: string) => {
  let partialTextKeyValue = textKeyValue;
  const splitTextKeyValue = [];

  while (partialTextKeyValue.length > 0) {
    const result = argumentRegex.exec(partialTextKeyValue);
    const indexOf = result?.index;

    if (result && typeof indexOf === 'number') {
      const [argument, argumentNumber] = result;

      if (indexOf > 0) {
        splitTextKeyValue.push(partialTextKeyValue.slice(0, indexOf));
      }

      splitTextKeyValue.push(parseInt(argumentNumber, 10));
      partialTextKeyValue = partialTextKeyValue.slice(indexOf + argument.length);
    } else {
      splitTextKeyValue.push(partialTextKeyValue);
      partialTextKeyValue = '';
    }
  }

  return splitTextKeyValue;
};

export default splitByArgument;
