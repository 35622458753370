import { makeVar, useReactiveVar } from '@apollo/client';
import deepEqual from 'deep-equal';

import type Currency from 'types/Currency';
import { type Configuration } from 'types/Configuration';
import findDefaultCurrency from './findDefaultCurrency';

const initialState: Currency = {
  code: '',
  symbol: '',
  label: 'common.currency.unknown',
};

const selectedCurrency = makeVar<Currency>(initialState);

const resetSelectedCurrency = () => {
  selectedCurrency(initialState);
};

/** Should be used directly only for tests purpopses. Use the hook otherwise */
export const UNSAFE_resetSelectedCurrency = resetSelectedCurrency;

const setSelectedCurrency = (currency: Currency) => {
  const current = selectedCurrency();
  if (!deepEqual(current, currency)) {
    selectedCurrency(currency);
  }
};

/** Should be used directly only for tests purpopses. Use the hook otherwise */
export const UNSAFE_setSelectedCurrency = setSelectedCurrency;

/** this is set to run in SettingsInitializer whenever the `pid` or `config` changes */
const initializeCurrentCurrency = (config: Configuration) => {
  const defaultCurrency = findDefaultCurrency(config);
  setSelectedCurrency(defaultCurrency);
};

const useCurrentCurrency = () => {
  const reactiveSelectedCurrency = useReactiveVar(selectedCurrency);

  return {
    actions: {
      setSelectedCurrency,
      initializeCurrentCurrency,
    },
    states: {
      currentCurrency: reactiveSelectedCurrency,
    },
  };
};

export default useCurrentCurrency;
