import type { SvgIconComponent } from '@mui/icons-material';
import type { FC, ComponentProps, ComponentType } from 'react';
import { type SvgIconProps, useTheme } from '@mui/material';

export type Icon = SvgIconComponent | FC<SvgIconProps>;

export type DirectionAwareIconProps<T extends Icon> = SvgIconProps &
  ComponentProps<T> & {
    icon: T;
    invertedIcon?: T;
  };

/**
 * Not all icons should be inverted when changing theme direction
 * This component should be used when an icon explicitly needs to change orientation
 * bases on the current language & theme direction. Note that the theme direction is set
 * bases on the current language.
 * @see https://m2.material.io/design/usability/bidirectionality.html#mirroring-layout
 */
const DirectionAwareIcon = <T extends Icon>(props: DirectionAwareIconProps<T>) => {
  const theme = useTheme();
  const { icon, invertedIcon, ...rest } = props;
  const IconLTR: ComponentType<SvgIconProps> = icon;
  const IconRTL: ComponentType<SvgIconProps> | undefined = invertedIcon;

  if (theme.direction === 'rtl') {
    return IconRTL === undefined ? <IconLTR sx={{ transform: 'scaleX(-1)' }} {...rest} /> : <IconRTL {...rest} />;
  } else {
    return <IconLTR {...rest} />;
  }
};

export default DirectionAwareIcon;
