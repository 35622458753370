import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import commonConfig from './commonConfig';
import getHeaders from './utils/getHeaders';

const createDynamicHttpLink = (baseUrl: string, operationName: string) => {
  return createHttpLink({
    uri: `${baseUrl}?${operationName}`,
  });
};

const namedLink = new ApolloLink((operation, forward) => {
  const { secure = true } = operation.getContext();

  const baseUrl = secure ? commonConfig.graphqlSecureBaseUrl : commonConfig.graphqlBaseUrl;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...getHeaders(),
    },
  }));

  const dynamicHttpLink = createDynamicHttpLink(baseUrl, operation.operationName);

  return dynamicHttpLink.request(operation, forward);
});

const graphqlClientConfig = new ApolloClient({
  connectToDevTools: !commonConfig.env.includes('prod'),
  cache: new InMemoryCache(),
  link: ApolloLink.from([namedLink]),
});

export default graphqlClientConfig;
