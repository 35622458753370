import { ScreenType } from 'types/screenType/ScreenType';
import useIsMobileScreen from './useIsMobileScreen';
import useIsTabletScreen from './useIsTabletScreen';

const useGetCurrentScreenType = () => {
  const { isMobile } = useIsMobileScreen().states;
  const { isTablet } = useIsTabletScreen().states;

  let currentScreenType: ScreenType;

  if (isMobile) {
    currentScreenType = ScreenType.Mobile;
  } else if (isTablet) {
    currentScreenType = ScreenType.Tablet;
  } else {
    currentScreenType = ScreenType.Desktop;
  }

  return {
    states: {
      currentScreenType,
    },
  };
};

export default useGetCurrentScreenType;
