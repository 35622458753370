import type { ThemeOptions } from '@mui/material';
import themesByAppName from '../../../states/theme/themesByAppName';

/**
 * Fetches the partner/appName themes from the `common/states/theme` directory
 */
export const fetchThemesByPid = async (
  appName: string | undefined,
  pid: string | undefined,
): Promise<ThemeOptions[]> => {
  if (appName && appName in themesByAppName) {
    const themes = await fetchThemeFiles(appName);
    return [themes.common, themes[pid ?? '']].filter(Boolean);
  }

  return await Promise.resolve([]);
};

export const fetchThemeFiles = async (appName: string | undefined) => {
  const { default: themes } = await themesByAppName[appName as keyof typeof themesByAppName]();
  return themes;
};
