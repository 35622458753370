import { gql } from '@apollo/client';

const GET_PARTNER_PERMISSIONS = gql`
  query getPermissionByPartnerPid($partnerPid: String) {
    getPermissionByPartnerPid(partnerPid: $partnerPid) {
      scopes
    }
  }
`;

export default GET_PARTNER_PERMISSIONS;
