import { useCallback } from 'react';
import { useNavigate, useLocation, matchRoutes, generatePath } from 'react-router-dom';

import { routes } from 'common/routes';

/**
 * an extension/companion of `react-router-dom`'s `useParams` hook
 * that will update the params present in the routes object for the active
 * route (ex: `/studio/:pid/whatever-else` where `pid` can be hot-swapped)
 * without causing a full page refresh or any sketchy business with just
 * appending the params to the end of `location.pathname`
 *
 * for instance, calling `setParams({ pid: 'EscapeAir' })`:
 * - `/studio/:pid` will be set into the url as `/studio/EscapeAir`
 * - `/studio/:pid/whatever-else` will be set into the url as `/studio/EscapeAir/whatever-else`
 *
 * I'm honestly baffled this didn't already exist: https://github.com/remix-run/react-router/discussions/11912
 */
const useSetParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const matchedRoute = matchRoutes(routes, location.pathname)?.at(-1); // assumes the most specific match is the last one in the cascade

  const setParams = useCallback(
    (params: { [key: string]: string }) => {
      if (matchedRoute?.route.path) {
        const path = generatePath(matchedRoute.route.path, {
          ...matchedRoute.params,
          ...params,
        });

        if (path !== location.pathname) navigate(path, { replace: true });
      } else {
        console.warn('Failed to determine matched route');
      }
    },
    [matchedRoute],
  );

  return {
    actions: {
      setParams,
    },
  };
};

export default useSetParams;
