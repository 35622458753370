import { type FC, type ReactNode } from 'react';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';

import commonConfig from 'common/configs/commonConfig';
import useAppConfig from 'common/states/config/useAppConfig';
import useAuthentication from 'common/states/auth/useAuthentication';
import useGetCurrentScreenType from 'common/libs/utils/screenSizes/useGetCurrentScreenType';

export interface FeatureFlagsProviderProps {
  children: ReactNode;
}

const optimizelyClient = commonConfig.optimizelyApiKey
  ? createInstance({
      sdkKey: commonConfig.optimizelyApiKey,
    })
  : undefined;
/**
 * Instatiates the `optimizely` client and passes `partnerConfig` as `user` `attributes`.
 *
 * Feature flags are then accessible via the hook `useRollout`.
 *
 * @see https://plusgrade.jira.com/wiki/spaces/FRON/pages/3740532808/
 */
const FeatureFlagsProvider: FC<FeatureFlagsProviderProps> = ({ children }) => {
  const partnerConfig = useAppConfig().states.config;
  const { authenticatedUser: user } = useAuthentication().states;
  const { currentScreenType } = useGetCurrentScreenType().states;

  if (!partnerConfig) return null; // we are assuming the user is logged in by requiring this
  if (!optimizelyClient) return children;

  /**
   * it would make sense to capture the sessionId here and somehow cross reference them upon login
   * https://docs.developers.optimizely.com/feature-experimentation/docs/handle-user-ids#tips-for-using-user-ids
   */
  const id = user?.id || 'unauthenticated';

  const attributes = {
    partnerType: partnerConfig.partnerType ?? 'unknown',
    language: partnerConfig.language,
    defaultCurrency: partnerConfig.defaultCurrency ?? 'unknown',
    currentScreenType,
  };

  return (
    <OptimizelyProvider optimizely={optimizelyClient} timeout={500} user={{ id, attributes }}>
      {children}
    </OptimizelyProvider>
  );
};

export default FeatureFlagsProvider;
