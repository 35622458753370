import type Language from 'types/Language';

// https://meta.wikimedia.org/wiki/Template:List_of_language_names_ordered_by_code
const rightToLeftLanguagesCode = new Set([
  'ar',
  'arc',
  'arz',
  'ckb',
  'dv',
  'fa',
  'ha',
  'he',
  'khw',
  'ks',
  'ps',
  'sd',
  'ur',
  'uz_AF',
  'yi',
]);

const isRightToLeft = (language: Language) => rightToLeftLanguagesCode.has(language.code);

export default isRightToLeft;
