export interface IDPConfig {
  idpHint: string;
  sessionTimeoutMinutes?: number;
}

export const DEFAULT_SESSION_TIMEOUT_MINUTES: number = 30;

export const DEFAULT_IDP_CONFIG: IDPConfig = {
  idpHint: 'login-required',
  sessionTimeoutMinutes: DEFAULT_SESSION_TIMEOUT_MINUTES,
};

export interface KeycloakConfig {
  realm: string;
  url: string;
  clientId: string;
  grantType: string;
  idpConfigByDomain: Record<string, IDPConfig>;
}
export interface CommonConfig {
  baseUrlCDN: string;
  graphqlBaseUrl: string;
  graphqlSecureBaseUrl: string;
  restSecureBaseUrl: string;
  staticBaseUrl: string;
  keycloak: KeycloakConfig;
  env: string;
  muixProLicense: string;
  optimizelyApiKey?: string;
  pastReportsPollingInterval: number;
}

// common config for all
const commonConfig: CommonConfig = {
  baseUrlCDN: 'https://upgrade-cdn.plusgrade.com/offer',
  graphqlBaseUrl: window.plusgrade?.graphqlBaseUrl ?? 'https://service-hub-partner.stage.plusgrade.com/graphql',
  graphqlSecureBaseUrl:
    window.plusgrade?.graphqlSecureBaseUrl ?? 'https://service-hub-partner.stage.plusgrade.com/secure/graphql',
  restSecureBaseUrl: window.plusgrade?.restSecureBaseUrl ?? 'https://service-hub-partner.stage.plusgrade.com',
  staticBaseUrl: window.plusgrade?.staticBaseUrl ?? 'https://static.plusgrade.com',
  keycloak: {
    clientId: window.plusgrade?.keycloak.clientId ?? 'ami-ui-client',
    grantType: window.plusgrade?.keycloak.grantType ?? 'password',
    realm: window.plusgrade?.keycloak.realm ?? 'pmt',
    url: window.plusgrade?.keycloak.url ?? 'https://id.stage.plusgrade.com/auth',
    idpConfigByDomain: window.plusgrade?.keycloak.idpConfigByDomain ?? { plusgrade: DEFAULT_IDP_CONFIG },
  },
  env: window.plusgrade?.env ?? 'local',
  muixProLicense: window.plusgrade?.muixProLicense ?? '',
  optimizelyApiKey: window.plusgrade?.optimizelyApiKey,
  pastReportsPollingInterval: 10000,
};

export default commonConfig;
