import { createRoot } from 'react-dom/client';
import App from './App';
import commonConfig from './configs/commonConfig';
import { LicenseInfo } from '@mui/x-license';
import isLocalDevelopment from './libs/utils/environments/isLocalDevelopment';

LicenseInfo.setLicenseKey(commonConfig.muixProLicense);

const root = document.getElementById('root');

if (root) {
  createRoot(root).render(<App />);
} else {
  console.error('Impossible to find root');
}

if ('serviceWorker' in navigator) {
  const serviceWorkerPath = isLocalDevelopment() ? '' : '/common';

  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register(`${serviceWorkerPath}/service-worker.js`)
      .then((registration) => {
        console.log('SW registered: ', registration);
      })
      .catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}
