type AnyObject = Record<string, unknown>;

function isObject(item: unknown): item is AnyObject {
  return item !== null && typeof item === 'object' && !Array.isArray(item);
}

function structuredMerge<T extends AnyObject, U extends AnyObject>(target: T, source: U): T & U {
  const output = structuredClone(target) as T & U;
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!(key in target)) {
          (output as AnyObject)[key] = structuredClone(source[key]);
        } else {
          (output as AnyObject)[key] = structuredMerge(
            (target as AnyObject)[key] as AnyObject,
            source[key] as AnyObject,
          );
        }
      } else {
        (output as AnyObject)[key] = source[key];
      }
    }
  }
  return output;
}

export default structuredMerge;
