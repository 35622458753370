const currencySymbolMapping = {
  AED: 'د.إ',
  AMD: '֏',
  ARS: '$',
  AUD: '$',
  AZN: '₼',
  BDT: '৳',
  BHD: 'د.ب.',
  BND: '$',
  BRL: 'R$',
  BWP: 'P',
  CAD: '$',
  CLP: '$',
  COP: '$',
  CHF: 'Fr.',
  CNY: '¥',
  CZK: 'Kč',
  DKK: 'KR',
  EGP: 'E£',
  ETB: 'Br',
  EUR: '€',
  FJD: '$',
  GEL: 'ლ',
  GBP: '£',
  HKD: '$',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  ISK: 'kr',
  JPY: '¥',
  JOD: 'JD',
  KES: 'Ksh',
  KRW: '₩',
  KWD: 'د.ك',
  KZT: '₸',
  LBP: 'ل.ل',
  LKR: 'Rs',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'ل.د',
  MAD: 'د.م.',
  MGA: 'Ar',
  MMK: 'K',
  MRO: 'UM',
  MUR: '₨',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: 'N$',
  NOK: 'kr',
  NPR: 'रु',
  NZD: '$',
  OMR: 'ر.ع.',
  PGK: 'K',
  PHP: '₱',
  PKR: 'Rs',
  PLN: 'zł',
  QAR: 'ر.ق',
  RON: 'lei',
  RUB: 'руб.',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SEK: 'kr',
  SGD: 'S$',
  SYP: '£',
  THB: '฿',
  TND: 'د.ت',
  TOP: 'T$',
  TRY: '₺',
  TTD: 'TT$',
  TWD: '$',
  UAH: '₴',
  USD: '$',
  VND: '₫',
  VUV: 'VT',
  WST: '$',
  XAF: 'F.CFA',
  XOF: 'CFA',
  XPF: 'F',
  YER: '﷼',
  ZAR: 'R',
  PTS: '', // pay with points
};

export default currencySymbolMapping;
