import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { fetchTextkeys } from 'common/states/translation/useTranslation';
import { type PartnerInfo } from 'types/Configuration';
import useAppConfig from 'common/states/config/useAppConfig';
import useCurrentCurrency from 'common/states/currency/useCurrentCurrency';

export interface SettingsInitializerProps extends Partial<PartnerInfo> {
  children: ReactNode;
}

const SettingsInitializer: FC<SettingsInitializerProps> = ({ children, pid }) => {
  const { config } = useAppConfig().states;
  const { initializeCurrentCurrency } = useCurrentCurrency().actions;

  useEffect(() => {
    if (pid && config) {
      fetchTextkeys(pid ?? 'Default', 'en').catch(console.error);
      if (config.currency || config.defaultCurrency) initializeCurrentCurrency(config);
    }
  }, [pid, config]);

  return <>{children}</>;
};
export default SettingsInitializer;
