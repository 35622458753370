import { gql } from '@apollo/client';

const GET_PARTNER_CONFIGURATION = gql`
  query getApplicationConfiguration($pid: String!) {
    getApplicationConfiguration(pid: $pid) {
      partnerName
      companyCode
      language
      partnerShortName
      partnerType
      supportedCurrencies
      currencyFormatSettings {
        stringFormat
        fractionDigits
        currencySpecificFormats {
          stringFormat
          currencyCode
          languageSpecificFormats {
            language
            stringFormat
          }
        }
      }
      currency
      defaultCurrency
      up {
        currency {
          global {
            stringFormat
            groupingSeparator
            decimalSeparator
            fractionDigits
          }
          currencySpecific {
            currencyCode
            stringFormat
            groupingSeparator
            decimalSeparator
            fractionDigits
          }
        }
      }
    }
  }
`;
export default GET_PARTNER_CONFIGURATION;
