import { useEffect } from 'react';
import { debounce } from '@mui/material';

const activityEvents: Array<keyof DocumentEventMap> = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'touchmove'];

const DEFAULT_EVENT_LISTENER_DEBOUNCE_MILLIS = 300;

const useTimeoutInactiveUser = (
  onInactiveTimeoutCallback: () => void,
  inactiveTimeoutMillis: number,
  debounceMillis: number = DEFAULT_EVENT_LISTENER_DEBOUNCE_MILLIS,
) => {
  useEffect(() => {
    // Set inactivity timer to execute timeout callback when due
    let inactiveTimer: ReturnType<typeof setTimeout>;

    const resetInactiveTimer = () => {
      if (inactiveTimer) {
        clearTimeout(inactiveTimer);
      }
      inactiveTimer = setTimeout(onInactiveTimeoutCallback, inactiveTimeoutMillis);
    };

    // Initialize inactive user timeout
    resetInactiveTimer();

    // We need to debounce the activity event listeners because we don't want the active user logic
    // to trigger thousands times a second for a mouse movement or a touch event for example
    const debouncedUserActivityHandler = debounce(resetInactiveTimer, debounceMillis);

    activityEvents.forEach((event) => {
      document.addEventListener(event, debouncedUserActivityHandler);
    });

    return () => {
      // Cleanup activity event listeneres
      activityEvents.forEach((event) => {
        document.removeEventListener(event, debouncedUserActivityHandler);
      });
      // Cleanup inactivity timer
      clearTimeout(inactiveTimer);
    };
  }, []);
};

export default useTimeoutInactiveUser;
