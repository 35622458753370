import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ThemeProvider from 'common/libs/providers/ThemeProvider';
import routes from './routes';

const router = createBrowserRouter(routes);

export default function App() {
  return (
    <ThemeProvider>
      <Suspense fallback={<></>}>
        <RouterProvider router={router} />
      </Suspense>
    </ThemeProvider>
  );
}
