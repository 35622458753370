import { makeVar, useReactiveVar } from '@apollo/client';
import deepEqual from 'deep-equal';

import type { Configuration, PartnerInfo } from 'types/Configuration';
import { PartnerType } from 'types/Configuration';
import type { PartialExcept } from 'types/PartialExcept';
import type { Permission } from 'types/Permissions';

export const defaultConfiguration: Configuration = {
  language: '',
  companyCode: '',
  partnerShortName: '',
  partnerType: PartnerType.airline,
};

const firstCompleteRenderState = makeVar(true);
const configuration = makeVar<Configuration>(defaultConfiguration);
const partnersState = makeVar<PartnerInfo[]>([]);
const permissionsState = makeVar<Permission[]>([]);
const partnerInfoState = makeVar<PartialExcept<PartnerInfo, 'pid'> | undefined>(undefined);

const setFirstCompleteRender = () => {
  const current = firstCompleteRenderState();
  if (current) firstCompleteRenderState(false);
};

/**
 * Should be used directly only for tests purpopses. Use the hook otherwise
 */
export const UNSAFE_resetConfiguration = () => {
  setConfiguration(defaultConfiguration);
};

const setConfiguration = (newConfiguration: Configuration) => {
  const currentConfiguration = configuration();
  if (!deepEqual(currentConfiguration, newConfiguration)) {
    configuration(newConfiguration);
  }
};

const setPartners = (newPartners?: PartnerInfo[]) => {
  const currentPartners = partnersState();
  if (!deepEqual(currentPartners, newPartners)) {
    partnersState(newPartners ?? []);
    partnerInfoState(undefined);
  }
};

const setPartnerInfo = (newPartner: PartnerInfo) => {
  const currentPartner = partnerInfoState();
  if (!deepEqual(currentPartner, newPartner)) {
    partnerInfoState(newPartner);
  }
};

const setPermissions = (newPermissions: Permission[]) => {
  const current = permissionsState();
  if (!deepEqual(current, newPermissions)) {
    permissionsState(newPermissions);
  }
};

const useAppConfig = () => {
  const firstCompleteRender = useReactiveVar(firstCompleteRenderState);
  const config = useReactiveVar(configuration);
  const partners = useReactiveVar(partnersState);
  const partnerInfo = useReactiveVar(partnerInfoState);
  const permissions = useReactiveVar(permissionsState);

  return {
    actions: {
      setFirstCompleteRender,
      setConfiguration,
      setPartners,
      setPartnerInfo,
      setPermissions,
    },
    states: {
      firstCompleteRender,
      config,
      partners,
      partnerInfo,
      permissions,
    },
    selectors: {
      hasPermission: (permission: Permission) => permissions?.includes(permission),
    },
  };
};

export default useAppConfig;
