import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isMobileScreen } from './screenSizes';

const useIsMobileScreen = () => {
  const theme = useTheme();

  return {
    states: {
      isMobile: useMediaQuery(isMobileScreen(theme)),
    },
  };
};

export default useIsMobileScreen;
