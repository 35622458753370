import { Fragment, type ReactNode } from 'react';
import type { TextKeyCode } from '../useTranslation';

const SUPPRESS_TEXT_KEYS = ['suppressTextKeys', 'suppressTextkeys'];

export const shouldRenderDebugTextKeys = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return SUPPRESS_TEXT_KEYS.some((s) => searchParams.get(s)?.toLowerCase() === 'true');
};

/**
 * Render the code with the list of args if there are any
 */
export const renderDebugTextKey = (code: TextKeyCode, args: Array<string | number>) => {
  const argAsString = args.length > 0 ? ` { ${args.join(' ; ')} }` : '';
  return `${code}${argAsString}`;
};

export const renderStyledDebugTextKey = (code: TextKeyCode, args: Array<ReactNode>): string => {
  const render = (
    <>
      {code}
      {args.length > 0 ? ' ' : ''}
      {args
        .map((arg, key) => {
          return { arg, key };
        })
        .map(({ arg, key }, index, array) => (
          <Fragment key={key}>
            {'{'}
            {arg}
            {'}'}
            {index < array.length - 1 ? ', ' : ''}
          </Fragment>
        ))}
    </>
  );

  return render as unknown as string;
};
