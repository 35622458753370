import { type Configuration } from 'types/Configuration';
import getCurrencies from './getCurrencies';

const findDefaultCurrency = (config: Configuration) => {
  const currencies = getCurrencies(config);

  const currencyCode = config.currency ?? config.defaultCurrency;

  return currencies.find((x) => x.code === currencyCode) ?? currencies[0];
};

export default findDefaultCurrency;
