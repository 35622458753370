/**
 * takes a user's fullName and returns initials in any language.
 * 
 * assumed we want only the first letter from first names, but 
 * for surnames (and multi-word last names) we attempt to take 
 * the first letter from each hyphenated portion
 * 
 * example:
 * ```
 * getInitials("Jean-Claude Van Damme"); // -> "JVD"
   getInitials("Mary-Jane Watson"); // -> "MW"
   getInitials("John Wick"); // -> "JW"
   getInitials("张伟"); // -> "张伟" (both characters are intitals)
   getInitials("محمد علي"); // -> "مع" (rtl)
```
 */
function getUserInitials(fullName?: string, defaults?: string, rtl: boolean = false): string {
  if (!fullName) return defaults ?? 'PG';

  const names = fullName.trim().split(/\s+/);

  // checks if the name is a single part and is in a CJK (Chinese, Japanese, Korean) script
  const matcher = /[\u4E00-\u9FFF\u3040-\u309F\u30A0-\u30FF\uAC00-\uD7AF]/;
  if (names.length === 1 && matcher.test(names[0])) return names[0];

  const processedNames = rtl ? names.toReversed() : names;
  const firstNameInitial = processedNames[0]?.[0]?.toUpperCase() || '';
  const surnameInitials = processedNames
    .slice(1)
    .flatMap((surname) => surname.split(/[-·]+/).map((part) => part[0]?.toUpperCase()))
    .join('');

  return firstNameInitial + surnameInitials;
}

export default getUserInitials;
