import commonConfig from './configs/commonConfig';

const plusgradeAssets = {
  faviconUrl: `${commonConfig.baseUrlCDN}/images/plusgrade-favicon-transparent.ico`,
  logo: `${commonConfig.baseUrlCDN}/images/plusgrade-large.svg`,
  icon: `${commonConfig.baseUrlCDN}/images/plusgrade-icon-large.svg`,
  logoLight: `${commonConfig.baseUrlCDN}/images/plusgrade-large-white.svg`,
};

export default plusgradeAssets;
