import type Currency from 'types/Currency';
import type { AllKeys } from '../translation/useTranslation';
import currencySymbolMapping from './currencySymbolMapping';
import isValidCurrencySymbol from './isValidCurrencySymbol';

const getCurrencyByCode = (code: keyof typeof currencySymbolMapping): Currency => {
  const symbol = isValidCurrencySymbol(code) ? currencySymbolMapping[code] : code;
  return {
    code,
    label: `common.currency.${code}` as keyof AllKeys,
    symbol,
  };
};

export default getCurrencyByCode;
