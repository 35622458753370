import type { FC, PropsWithChildren } from 'react';
import { SnackbarProvider as NotiSnackbarProvider, MaterialDesignContent, closeSnackbar } from 'notistack';
import { IconButton, styled } from '@mui/material';
import { isMobileScreen } from '../../utils/screenSizes/screenSizes';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
/**
 * It is impossible to customize the Snackbar using MuiSnackbar since we use notistack
 * So we have to customize using styled components
 * @see https://notistack.com/features/customization
 */
const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => `
  &.notistack-Snackbar {
    box-shadow: ${theme.shadows[0]};
  }

  &.notistack-MuiContent {
    width: 40rem; ${'' /* seems not worksing for warning and info */}
  }

  ${isMobileScreen(theme)} {
    &.notistack-MuiContent {
      width: 80vw; ${'' /* seems not worksing for warning and info */}
      margin: auto; ${'' /* seems not worksing for warning and info */}
    }
  }

  &.notistack-MuiContent-success {
    background-color: ${theme.palette.success.light};
    color: ${theme.palette.success.contrastText};
  }

  &.notistack-MuiContent-error {
    background-color: ${theme.palette.error.light};
    color: ${theme.palette.error.contrastText};
  }

  &.notistack-MuiContent-warning {
    background-color: ${theme.palette.warning.light};
    color: ${theme.palette.warning.contrastText};
  }

  &.notistack-MuiContent-info {
    background-color: ${theme.palette.info.light};
    color: ${theme.palette.info.contrastText};
  }
`,
);

const SIX_SECONDS = 6 * 1000;

const closeAction = (snackbarId: string) => (
  <IconButton color="inherit" onClick={() => closeSnackbar(snackbarId)}>
    <CloseIcon />
  </IconButton>
);

/**
 * notistack offers a lot of benefits like an imperative way to display snackbar
 * and a stack. With MUI the snack bar are displayed over each other so only one is visible
 * at a time
 */
const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => (
  <NotiSnackbarProvider
    autoHideDuration={SIX_SECONDS}
    maxSnack={3}
    variant="success"
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    action={closeAction}
    Components={{
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
    }}
    iconVariant={{
      success: <CheckIcon fontSize="small" color="inherit" sx={{ mr: 1 }} />,
    }}
  >
    {children}
  </NotiSnackbarProvider>
);

export default SnackbarProvider;
