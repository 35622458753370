import { useEffect, type FC, type PropsWithChildren } from 'react';
import { matchRoutes, useNavigate } from 'react-router-dom';

import routes from 'common/routes';

import useAppConfig from 'common/states/config/useAppConfig';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type -- intentional
export interface PermissionGuardProps {}

const PermissionGuard: FC<PropsWithChildren<PermissionGuardProps>> = ({ children }) => {
  const match = matchRoutes(routes, location.pathname)?.at(-1); // assumes the most specific match is the last one in the cascade
  const { hasPermission } = useAppConfig().selectors;
  const navigate = useNavigate();

  useEffect(() => {
    if (match?.route.permission && !hasPermission(match.route.permission)) {
      navigate('/404', { replace: true });
    }
  }, [match?.route.permission, hasPermission, navigate]);

  if (match?.route.permission && !hasPermission(match.route.permission)) {
    return null;
  }

  return children;
};

export default PermissionGuard;
