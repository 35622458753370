import { StrictMode, useEffect, type ReactNode } from 'react';
import useAuthentication from 'common/states/auth/useAuthentication';

export interface KeycloakProviderProps {
  children: ReactNode;
}

export const AuthenticationProvider = ({ children }: KeycloakProviderProps) => {
  const {
    actions: { initAuthenticatedUser },
    states: { loading },
  } = useAuthentication();

  useEffect(() => {
    initAuthenticatedUser();
  }, []);

  if (loading) return <></>;

  // The StrictMode causes the useEffect to be executed twice,
  // which is not well handled by keycloak library and causes UI to enter an infinite loop of refreshing.
  // That's why we need to keep it at this level
  return <StrictMode>{children}</StrictMode>;
};

export default AuthenticationProvider;
