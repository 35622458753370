import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const useIsTabletScreen = () => {
  const theme = useTheme();

  return {
    states: {
      isTablet: useMediaQuery(theme.breakpoints.down('lg')),
    },
  };
};

export default useIsTabletScreen;
