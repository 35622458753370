import { makeVar, useReactiveVar } from '@apollo/client';
import Keycloak from 'keycloak-js';
import deepEqual from 'deep-equal';

import type { AuthenticatedUser } from 'types/AuthenticatedUser';
import commonConfig, { DEFAULT_IDP_CONFIG } from 'common/configs/commonConfig';
import getInitials from './utils/getUserInitials';
import { getDomainIdentityProviderConfig } from 'common/configs/identityProviderConfig';

const keycloak = makeVar<Keycloak | undefined>(undefined);
const isAuthenticated = makeVar<boolean>(false);
const authenticatedUser = makeVar<AuthenticatedUser | undefined>(undefined);

export const IDP_HINT_STORAGE_KEY = 'IDP_HINT_STORAGE_KEY';
export const USERNAME_HINT_STORAGE_KEY = 'USERNAME_HINT_STORAGE_KEY';
export const AUTH_TOKEN = 'AUTH_TOKEN';
const FORCE_REFRESH_MIN_VALIDITY = -1;

const init = async () => {
  try {
    const kc = new Keycloak(commonConfig.keycloak);
    kc.onTokenExpired = () => {
      logout();
    };
    const idpHint = window.localStorage.getItem(IDP_HINT_STORAGE_KEY) ?? DEFAULT_IDP_CONFIG.idpHint;
    const loginHint = window.localStorage.getItem(USERNAME_HINT_STORAGE_KEY) ?? undefined;
    const isAuthenticated = await kc.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
    });

    if (kc.token) {
      window.localStorage.setItem(AUTH_TOKEN, kc.token);
    }

    if (!isAuthenticated) await kc.login({ idpHint, loginHint });

    setKeycloak(kc);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    throw new Error('useAuthentication.init: Failed to initialize the authentication service');
  }
};

async function initAuthenticatedUser() {
  try {
    await init();
    const currentUser: AuthenticatedUser | undefined = getCurrentUser();
    setAuthenticatedUser(currentUser);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    throw new Error('useAuthentication.initAuthenticatedUser: Failed to initialize authenticated user');
  }
}

async function logout() {
  try {
    await keycloak()?.logout();
    resetAuthenticatedUser();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    throw new Error('useAuthentication.logout: Failed to logout user');
  }
}

async function setHint({ email }: { email: string }) {
  try {
    const { idpHint } = getDomainIdentityProviderConfig(email);
    localStorage.setItem(USERNAME_HINT_STORAGE_KEY, email);
    if (idpHint) localStorage.setItem(IDP_HINT_STORAGE_KEY, idpHint);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    throw new Error('useAuthentication.setHint: Failed to setHint for user');
  }
}

async function updateToken() {
  try {
    await keycloak()?.updateToken(FORCE_REFRESH_MIN_VALIDITY);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    // logout user & re-direct to the login page because the KC session has expired
    keycloak()?.logout();
  }
}

const setKeycloak = (newInstance: Keycloak | undefined) => {
  const currentConfiguration = keycloak();
  if (!deepEqual(currentConfiguration, newInstance)) {
    keycloak(newInstance);
  }
};

const resetAuthenticatedUser = () => {
  setAuthenticatedUser(undefined);
};

const setIsAuthenticated = (newValue: boolean) => {
  const currentIsAuthenticated = isAuthenticated();
  if (currentIsAuthenticated !== newValue) {
    isAuthenticated(newValue);
  }
};

const setAuthenticatedUser = (newUser: AuthenticatedUser | undefined) => {
  const currentConfiguration = authenticatedUser();
  if (!deepEqual(currentConfiguration, newUser)) {
    authenticatedUser(newUser);
  }
  setIsAuthenticated(Boolean(newUser));
};

const getCurrentUser = (): AuthenticatedUser | undefined => {
  const kc = keycloak();
  if (!kc?.tokenParsed) return undefined;

  const { token, tokenParsed } = kc;

  return {
    id: tokenParsed.sub,
    email: tokenParsed.email,
    jwtToken: token,
    fullName: `${tokenParsed.given_name} ${tokenParsed.family_name}`,
    firstName: tokenParsed.given_name,
    lastName: tokenParsed.family_name,
  };
};

const useAuthentication = () => {
  const kc = useReactiveVar(keycloak);
  const authenticated = useReactiveVar(isAuthenticated);
  const user = useReactiveVar(authenticatedUser);

  return {
    actions: {
      initAuthenticatedUser,
      updateToken,
      logout,
      setHint,
    },
    states: {
      loading: !kc,
      isAuthenticated: authenticated,
      authenticatedUser: user,
    },
    selectors: {
      getJWTToken: () => kc?.token,
      getUserInitials: (companyCode?: string, rtl?: boolean) => getInitials(user?.fullName, companyCode, rtl),
    },
  };
};

export default useAuthentication;
