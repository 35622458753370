// No dynamic language functionality yet
// Placeholder code that just returns 'en' for the purpose of building out other features

import { makeVar, useReactiveVar } from '@apollo/client';
import deepEqual from 'deep-equal';

import type Language from 'types/Language';

const initialState: Language = {
  code: 'en',
  label: 'common.language.en',
};

const currentLanguage = makeVar<Language>(initialState);

/**
 * Should be used directly only for tests purpopses. Use the hook otherwise
 */
export const resetCurrentLanguage = () => currentLanguage(initialState);

/**
 * Should be used directly only for tests purpopses. Use the hook otherwise
 */
export const setCurrentLanguage = (language: Language) => {
  const current = currentLanguage();
  if (!deepEqual(current, language)) {
    currentLanguage(language);
  }
};

const useCurrentLanguage = () => {
  const reactiveCurrentLanguage = useReactiveVar(currentLanguage);

  return {
    actions: {},
    states: {
      currentLanguage: reactiveCurrentLanguage,
    },
  };
};

export default useCurrentLanguage;
