import { gql } from '@apollo/client';

const GET_PARTNER_GROUP_FROM_TOKEN = gql`
  query getPartnerGroupFromToken {
    getPartnerGroupFromToken {
      name
      partners {
        pid
        companyCode: code
        partnerShortName: shortName
        partnerType
      }
    }
  }
`;

export default GET_PARTNER_GROUP_FROM_TOKEN;
