import { useEffect, useState, type FC, type PropsWithChildren } from 'react';
import useAuthentication from 'common/states/auth/useAuthentication';
import useTimeoutInactiveUser from 'common/states/session/useTimeoutInactiveUser';
import { DEFAULT_SESSION_TIMEOUT_MINUTES } from 'common/configs/commonConfig';
import { getIdentityProviderConfig } from 'common/configs/identityProviderConfig';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DirectionAwareIcon from 'common/libs/atoms/DirectionAwareIcon';
import { Logout } from '@mui/icons-material';
import useTranslation from 'common/states/translation/useTranslation';

export const makeDataTestId = (dataTestId = 'InactiveTimoutGuard') => {
  return {
    prefix: dataTestId,
    timeoutDialog: `${dataTestId}.dialog`,
    stayInBtn: `${dataTestId}.stayInBtn`,
    logoutBtn: `${dataTestId}.logoutBtn`,
  };
};

export interface InactiveTimeoutGuardProps {
  dataTestId?: string;
}

const LOGOUT_COUNTDOWN_SECONDS = 30;
const ONE_MINUTE_MS = 1000 * 60;

const InactiveTimeoutGuard: FC<PropsWithChildren<InactiveTimeoutGuardProps>> = ({ children, dataTestId }) => {
  const allDataTestId = makeDataTestId(dataTestId);
  const { t } = useTranslation().actions;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [countdownStarted, setCountdownStarted] = useState<boolean>(false);
  const [secondsRemaining, setSecondsRemaining] = useState<number>(0);
  const {
    actions: { logout, updateToken },
  } = useAuthentication();

  const domainIDPConfig = getIdentityProviderConfig();
  const inactiveTimeoutMinutes = domainIDPConfig.sessionTimeoutMinutes ?? DEFAULT_SESSION_TIMEOUT_MINUTES;
  const timeoutMillis = inactiveTimeoutMinutes * 60 * 1000;

  const onInactiveUser = () => {
    setIsDialogOpen(true);
    setSecondsRemaining(LOGOUT_COUNTDOWN_SECONDS);
    setCountdownStarted(true);
  };

  const onStayLoggedIn = () => {
    setIsDialogOpen(false);
    setCountdownStarted(false);
    setSecondsRemaining(0);
  };

  useEffect(() => {
    if (countdownStarted) {
      const countdownInterval = setInterval(() => {
        if (secondsRemaining <= 1) {
          //countdown completed
          clearInterval(countdownInterval);
          logout();
        }
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
      return () => clearInterval(countdownInterval);
    }
  }, [countdownStarted, secondsRemaining]);

  useEffect(() => {
    const updateTokenInterval = setInterval(() => {
      updateToken();
    }, ONE_MINUTE_MS);
    return () => clearInterval(updateTokenInterval);
  }, []);

  useTimeoutInactiveUser(onInactiveUser, timeoutMillis);

  return (
    <>
      {children}
      <Dialog open={isDialogOpen} onClose={onStayLoggedIn} data-testid={allDataTestId.timeoutDialog}>
        <DialogTitle>{t('common.session.timeout.dialog-title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('common.session.timeout.inactive-text', inactiveTimeoutMinutes)}</DialogContentText>
          <DialogContentText>{t('common.session.timeout.logout-text', secondsRemaining)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={async () => logout()}
            data-testid={allDataTestId.logoutBtn}
          >
            {t('common.user-modal.logout', <DirectionAwareIcon icon={Logout} />)}
          </Button>
          <Button variant="contained" color="primary" onClick={onStayLoggedIn} data-testid={allDataTestId.stayInBtn}>
            {t('common.session.timeout.stay-in.button-label')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InactiveTimeoutGuard;
