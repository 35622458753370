import type { FC, ReactNode } from 'react';
import type { Theme } from '@mui/material';
import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';

import useCurrentTheme from './useCurrentTheme';
import type { UseBuildThemeHookProps } from './useCurrentTheme';
import useCurrentLanguage from 'common/states/language/useCurrentLanguage';
import isRightToLeft from 'common/libs/utils/languages/isRightToLeft';

export interface ThemeProviderProps extends UseBuildThemeHookProps {
  children: ReactNode;
}

export const ThemeProviderMarkup: FC<{ theme: Theme; children: ReactNode }> = ({ theme, children }) => (
  <>
    {theme !== undefined && (
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    )}
  </>
);

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const theme = useCurrentTheme();
  const { currentLanguage } = useCurrentLanguage().states;

  return theme ? (
    <ThemeProviderMarkup theme={{ ...theme, direction: isRightToLeft(currentLanguage) ? 'rtl' : 'ltr' }}>
      {children}
    </ThemeProviderMarkup>
  ) : null;
};

ThemeProvider.displayName = 'ThemeProvider';

export default ThemeProvider;
