export const SESSION_ID_KEY = 'pg-ami-session-id';

function getSessionId() {
  let sessionIdValue = sessionStorage.getItem(SESSION_ID_KEY);
  if (!sessionIdValue) {
    sessionIdValue = crypto.randomUUID();
    sessionStorage.setItem(SESSION_ID_KEY, sessionIdValue);
  }
  return sessionIdValue;
}

export default getSessionId;
